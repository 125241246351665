jQuery( document ).ready(function($) {
  
  $("#nashville-cma-fest-newsletter-signup-45press, #nashville-cma-fest-newsletter-signup-45press-2").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        if( e.target.id === 'nashville-cma-fest-newsletter-signup-45press' ) {
          $('.newsletter-alerts .success').show()
          $('.newsletter-alerts .error').hide()
          $('#nashville-cma-fest-newsletter-signup-45press').hide()
        } else {
          $('.newsletter-alerts-2 .success').show()
          $('.newsletter-alerts-2 .error').hide()
          $('#nashville-cma-fest-newsletter-signup-45press-2').hide()
        }
      },
      error: function (err) {
        if( e.target.id === 'nashville-cma-fest-newsletter-signup-45press' ) {
          $('.newsletter-alerts .error').show()
        } else {
          $('.newsletter-alerts-2 .error').show()
        }
      }
    });
  });

});